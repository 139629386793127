import React from "react";
import { linkResolver } from "../../utils/linkResolver";
import "./style.css";

export default ({ title, teaser, image, alt, link, btnText }) => (
  <React.Fragment>
    <div className="interior-hero-wrapper">
      <div className="interior-hero-info">
        <h1 className="interior-hero-title text-med">
          { title }
        </h1>
        <h5 className="interior-hero-teaser text-reg">
          { teaser }
        </h5>
        <a className="interior-hero-button text-med" href={linkResolver(link)}>
          { btnText }
        </a>
      </div>
      <img src={ image } alt={ alt } className="interior-hero-image" />
    </div>
  </React.Fragment>
)