import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import "../components/layout.css";

import NavBar from "../components/NavBar";
import IntHero from "../components/InteriorHero";
import Feature from "../components/Feature";
import Benefit from "../components/BenefitRight";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

class BrandStory extends Component {

  render() {

    const {
      data: { seo, hero, features, benefits, contact },
    } = this.props

    console.log(hero.nodes);

    const seoData = seo.nodes[0].data.body1[0].primary;
    console.log(seoData);

    const heroData = hero.nodes[0].data;

    const contactData = contact.nodes[0].primary.cta.document.data;

    return(
      <React.Fragment>
        <SEO 
          title={ seoData.seo_title }
          description={ seoData.seo_description }
          />
        <div className="page-container interior-page">
          <NavBar />
          <IntHero
            title={ heroData.page_title.text }
            teaser={ heroData.hero_teaser.text }
            image={ heroData.hero_image.url }
            alt={ heroData.hero_image.alt }
            link={ heroData.hero_button_link.uid }
            btnText={ heroData.hero_button_label }
            />
          <div className="feature-section-wrapper">
            {features.nodes[0].items.map((f, i) => {
              const featureData = f.features.document.data;
              return (
                <Feature
                  title={ featureData.feature_title.text }
                  teaser={ featureData.feature_teaser.text }
                  image={ featureData.feature_image.url }
                  alt={ featureData.feature_image.alt }
                  />
              )
            })}
          </div>
          <div className="benefit-section-wrapper">
            {benefits.nodes[0].items.map((b, i) => {
              const benefitData = b.benefit.document.data;
              return (
                <Benefit
                  title={ benefitData.benefit_title.text }
                  subtitle={ benefitData.benefit_subtitle.text }
                  teaser={ benefitData.benefit_teaser_text.text }
                  image={ benefitData.benefit_image.url }
                  alt={ benefitData.benefit_image.alt }
                  />
              )
            })}
        </div>
        <Contact
          title={ contactData.contact_field_title.text }
          teaser={ contactData.contact_field_teaser.text }
          portalId={ contactData.hubspot_portal_id.text }
          formId={ contactData.hubspot_form_id.text }
          />
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default BrandStory

BrandStory.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    hero: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    features: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    benefits: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    contact: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const brandStoryQuery = graphql`
query brandStory {

  seo: allPrismicInteriorPage(sort: {fields: first_publication_date}) {
    nodes {
      data {
        page_title {
          text
        }
        body1 {
          ... on PrismicInteriorPageBody1SeoTags {
            primary {
              seo_title
              seo_description
            }
          }
        }
      }
    }
  }

  hero: allPrismicInteriorPage(sort: {fields: first_publication_date}) {
    nodes {
      data {
        page_title {
          text
        }
        hero_teaser {
          text
        }
        hero_image {
          url
          alt
        }
        hero_button_link {
          uid
        }
        hero_button_label
      }
    }
  }

  features: allPrismicInteriorPageBodyFeatureBlock {
    nodes {
      items {
        features {
          document {
            ... on PrismicFeature {
              data {
                feature_title {
                  text
                }
                feature_teaser {
                  text
                }
                feature_image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
  }

  benefits: allPrismicInteriorPageBodyBenefitBlock {
    nodes {
      items {
        benefit {
          document {
            ... on PrismicBenefitBlo {
              data {
                benefit_title {
                  text
                }
                benefit_teaser_text {
                  text
                }
                benefit_subtitle {
                  text
                }
                benefit_image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
  }

  contact: allPrismicInteriorPageBodyCta {
    nodes {
      primary {
        cta {
          document {
            ... on PrismicCta {
              data {
                contact_field_title {
                  text
                }
                contact_field_teaser {
                  text
                }
                hubspot_portal_id {
                  text
                }
                hubspot_form_id {
                  text
                }
              }
            }
          }
        }
      }
    }
  }

}
`